import React from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { DEVICE } from '@/utils/constants/breakpoints';
import { SECTION_PATHS } from '@/utils/constants/sectionPaths';

import { useMediaQuery } from 'usehooks-ts';
import { LanguageContent } from '@/hooks/useLanguage';

import { sanitize } from 'isomorphic-dompurify'

import ctaBannerImg from '@/assets/images/cta_banner.png'
import ctaBannerMobileImg from '@/assets/images/cta_banner_mobile.png'
import chatIcon from '@/assets/images/chat-icon.svg'

import { Container, CallToAction, InfoContent } from './styles';

export function CtaSection({ title, mainText, secondText, button, imageAlt }: LanguageContent['cta']) {
  const matches = useMediaQuery(DEVICE.tablet)

  return (
    <Container data-testid="cta-section">
      <InfoContent>
        <article>
          <h2>{title}</h2>
          <div dangerouslySetInnerHTML={{ __html: sanitize(mainText) }} />
        </article>
      </InfoContent>

      <CallToAction>
        <Image
          className="background"
          src={matches ? ctaBannerImg : ctaBannerMobileImg}
          alt="HomePage Illustration"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          loading="lazy"
        />
        <div>
          <Image src={chatIcon} width={88} height={117} alt={imageAlt} />
          <p>{secondText}</p>
          <Link href={`#${SECTION_PATHS.contact}`}>
            <a>{button}</a>
          </Link>
        </div>
      </CallToAction>

    </Container>
  );
}
