import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9.563rem 0 0;

  > nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 0 3rem;
    gap: 3.75rem;

    h2 {
      font-size: 2.625rem;
      font-family: ${(props) => props.theme.fonts?.second};
      text-align: center;
      margin: 0;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
      grid-auto-flow: row;
      gap: 3.125rem;
      width: 100%;
      max-width: 1100px;
      list-style: none;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;

      width: 100%;
      height: 5rem;

      transition: all ease-in-out 0.2s;

      span {
        width: 100%;
        height: 100%;
      }

      &:hover,
      &:focus {
        padding-bottom: 2rem;
      }
    }
  }
`
