import styled from 'styled-components'
import { DEVICE } from '@/utils/constants/breakpoints'

export const Container = styled.section`
  width: 100%;
  padding: 3rem 1rem 5rem;

  @media ${DEVICE.tablet} {
    padding: 10.625rem 2rem 5rem;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  height: 100%;
  min-height: max-content;

  > span {
    background: ${(props) => props.theme.colors?.primary};
    color: ${(props) => props.theme.colors?.base50};
    font-family: ${(props) => props.theme.fonts?.second};
    font-size: 1.125rem;
    font-weight: bold;
    padding: 5px 15px;
    border-radius: 5px;
  }
  > h2 {
    color: ${(props) => props.theme.colors?.base700};
    font-family: ${(props) => props.theme.fonts?.second};
    font-size: 2.625rem;
    font-weight: bold;
    z-index: 1;
    max-width: 19.125rem;
    letter-spacing: -1.26px;
    line-height: 3.125rem;
    margin: 1.563rem 0 0;

    @media ${DEVICE.tablet} {
      max-width: max-content;
    }
  }

  > p {
    font-size: 1.125rem;
    font-family: ${(props) => props.theme.fonts?.primary};
    color: ${(props) => props.theme.colors?.base800};
    margin: 0;
    max-width: 576px;
    text-align: center;
    z-index: 20;
    padding: 0 2rem;
    margin: 1.563rem 0 0.875rem 0;
    @media ${DEVICE.tablet} {
      max-width: max-content;
      margin: 1.563rem 0 4.5rem 0;
    }

    @media ${DEVICE.tablet} {
      max-width: 576px;
    }

    a {
      color: ${(props) => props.theme.colors?.primary};
      text-decoration: none;
      font-weight: 600;
    }
  }

  > nav {
    width: 100%;
    display: flex;
    text-align: left;
    @media ${DEVICE.mobileL} {
      max-width: 1000px;
    }

    @media ${DEVICE.laptopL} {
      max-width: 1300px;
    }
  }
`
