import React from 'react';

import Image from 'next/image';

import { SECTION_PATHS } from '@/utils/constants/sectionPaths';

import { LanguageContent } from '@/hooks/useLanguage';

import { Container } from './styles';
import { TECHS } from '@/utils/constants/technologies';

export function TechnologiesSection({ title }: LanguageContent['technologies']) {
  return (
    <Container
      data-testid="technologies-section"
      id={SECTION_PATHS.technology}
    >
      <nav>
        <h2>{title}</h2>

        <ul>
          {TECHS.map((tech, index) => (
            <li key={`${tech.alt} - ${index}`} title={tech.alt}>
              <Image
                src={tech.src}
                objectFit="contain"
                layout='fill'
                alt={tech.alt}
                loading="lazy"
              />
            </li>
          ))}
        </ul>
      </nav>
    </Container>
  );
}
