import andreImg from '@/assets/images/team_photos/andre.webp'
import rafaelImg from '@/assets/images/team_photos/rafael.webp'
import ewertonImg from '@/assets/images/team_photos/ewerton.webp'
import joseImg from '@/assets/images/team_photos/jose.webp'
import raphaelImg from '@/assets/images/team_photos/raphael.webp'
import joaoImg from '@/assets/images/team_photos/joao.webp'
import maurineyImg from '@/assets/images/team_photos/mauriney.webp'
import renatoImg from '@/assets/images/team_photos/renato.webp'
import robertImg from '@/assets/images/team_photos/robert.webp'
import jeanImg from '@/assets/images/team_photos/jean.webp'
import mauroImg from '@/assets/images/team_photos/mauro.webp'
import tiagoImg from '@/assets/images/team_photos/tiago.webp'
import latochImg from '@/assets/images/team_photos/latoch.webp'
import gustavoImg from '@/assets/images/team_photos/gustavo.webp'
import vitorImg from '@/assets/images/team_photos/vitor.webp'
import davidImg from '@/assets/images/team_photos/david.webp'

const TEAM = [
  {
    name: 'André Antunes',
    url: andreImg.src,
    occupation: 'Scrum Master',
  },
  {
    name: '',
    url: '',
    occupation: '',
    blue: true,
  },
  {
    name: 'David Magalhães',
    url: davidImg.src,
    occupation: 'Developer',
  },
  {
    name: '',
    url: '',
    occupation: '',
    blue: true,
  },
  {
    name: 'Rafael Alves',
    url: rafaelImg.src,
    occupation: 'Developer',
  },
  {
    name: 'Ewerton Lourenço',
    url: ewertonImg.src,
    occupation: 'Developer',
  },
  {
    name: '',
    url: '',
    occupation: '',
  },
  {
    name: 'José Vilafranca',
    url: joseImg.src,
    occupation: 'Developer',
  },
  {
    name: '',
    url: '',
    occupation: '',
  },
  {
    name: 'Raphael de Oliveira',
    url: raphaelImg.src,
    occupation: 'QA Analyst',
  },
  {
    name: '',
    url: '',
    occupation: '',
  },
  {
    name: '',
    url: '',
    occupation: '',
  },
  {
    name: '',
    url: '',
    occupation: '',
    blue: true,
  },
  {
    name: 'Gustavo Amorim',
    url: gustavoImg.src,
    occupation: 'Developer',
  },
  {
    name: 'Mauriney Rodrigues',
    url: maurineyImg.src,
    occupation: 'Developer',
  },
  {
    name: '',
    url: '',
    occupation: '',
    blue: true,
  },
  {
    name: 'Vitor Martini',
    url: vitorImg.src,
    occupation: 'Developer',
  },
  {
    name: '',
    url: '',
    occupation: '',
    blue: true,
  },
  {
    name: 'Renato Molina',
    url: renatoImg.src,
    occupation: 'Product Manager',
  },
  {
    name: 'Robert Bialli Junior',
    url: robertImg.src,
    occupation: 'QA Analyst',
  },
  {
    name: '',
    url: '',
    occupation: '',
  },
  {
    name: '',
    url: '',
    occupation: '',
    blue: true,
  },
  {
    name: 'Jean Alves',
    url: jeanImg.src,
    occupation: 'Developer',
  },
  {
    name: '',
    url: '',
    occupation: '',
  },
  {
    name: 'Mauro Grabowski Junior',
    url: mauroImg.src,
    occupation: 'CTO',
  },
  {
    name: 'Tiago Cristiano Alves',
    url: tiagoImg.src,
    occupation: 'Tech Lead',
  },
  {
    name: '',
    url: '',
    occupation: '',
    blue: true,
  },
  {
    name: '',
    url: '',
    occupation: '',
  },
  {
    name: 'Ricardo da Silva Latoch',
    url: latochImg.src,
    occupation: 'Tech Lead',
  },
  {
    name: '',
    url: '',
    occupation: '',
    blue: true,
  },
  {
    name: 'João Paulo de Alencar',
    url: joaoImg.src,
    occupation: 'Developer',
  },
  {
    name: '',
    url: '',
    occupation: '',
    blue: true,
  },
]

export { TEAM }
