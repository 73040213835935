import React from 'react';

import Image from 'next/image';

import { LanguageContent } from '@/hooks/useLanguage';

import { Container } from './styles';

import { SATISFIED_COSTUMERS } from '@/utils/constants/costumers';

import customersBannerImg from '@/assets/images/customers_banner.png'

export function CostumerSatisfiedSection({ tag, title }: LanguageContent['costumersSatisfied']) {
  return (
    <Container data-testid="costumer-satisfied-section">
      <span>{tag}</span>
      <h3>{title}</h3>

      <nav>
        <Image
          className="background"
          src={customersBannerImg}
          alt="HomePage Illustration"
          layout="fill"
          objectFit="cover"
          objectPosition="left"
          loading="lazy"
        />
        <ul>
          {SATISFIED_COSTUMERS.map((tech, index) => (
            <li key={`${tech.alt} - ${index}`} title={tech.alt}>
              <Image
                src={tech.src}
                objectFit="contain"
                layout='fill'
                alt={tech.alt}
                loading="lazy"
              />
            </li>
          ))}
        </ul>
      </nav>
    </Container>
  );
}
