import styled from 'styled-components'
import { DEVICE } from '@/utils/constants/breakpoints'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors?.base800};
  border-radius: ${(props) => props.theme.borderRadius?.small};

  transition: all 200ms ease-in-out;

  height: 100%;

  figure {
    margin: 0;
    display: flex;
    align-items: center;
    height: max-content;
  }

  > div {
    background: ${(props) => props.theme.colors?.base50};
    border: 1px solid ${(props) => props.theme.colors?.base200};
    border-radius: ${(props) => props.theme.borderRadius?.small};
    padding: 1.265rem 1.375rem;
    height: 100%;
    flex: 1;

    @media ${DEVICE.tablet} {
      display: flex;
      flex-direction: column;
      gap: 1.75rem;
      padding: 3.125rem;
      text-align: left;
    }

    figure {
      min-width: 65px;
      min-height: 65px;
      margin-bottom: 1rem;
    }

    h3 {
      font-family: ${(props) => props.theme.fonts?.second};
      font-weight: 600;
      text-align: left;
      font-size: 1.813rem;
      line-height: 30px;
      color: ${(props) => props.theme.colors?.base700};
      margin: 0;
    }

    p {
      font-family: ${(props) => props.theme.fonts?.primary};
      font-size: 1.125rem;
      letter-spacing: 0px;
      line-height: 1.625rem;
      max-width: 475px;
      text-align: left;
      margin: 1.875rem 0;
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.063rem;
      padding-left: 1rem;
      letter-spacing: 0px;
      line-height: 1.375rem;
      align-items: flex-start;
      gap: 1.875rem;
      color: ${(props) => props.theme.colors?.base800};

      li {
        display: flex;
        gap: 10px;
        text-align: start;

        &::before {
          content: ' ';
          width: 36px;
          height: 12px;
          margin-top: 5px;
          background: url('/check.svg') no-repeat center center;
          filter: invert(55%) sepia(92%) saturate(2243%) hue-rotate(170deg)
            brightness(94%) contrast(88%);
        }
      }
    }

    > article {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media ${DEVICE.tablet} {
        height: 100%;
      }

      > article {
        height: 100%;
      }
    }

    article div {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      width: 100%;
      align-self: flex-end;
      @media ${DEVICE.tablet} {
        padding-top: 2.75rem;
      }
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.563rem 1.25rem;
    min-height: 70px;
    color: ${(props) => props.theme.colors?.base50};
    text-align: left;
    flex-direction: row;
    gap: 0.875rem;
    padding: 0 1.25rem;

    figure {
      min-width: 29px;
      min-height: 29px;
    }

    @media ${DEVICE.tablet} {
    }
  }

  &:focus,
  &:hover {
    filter: drop-shadow(0 0 0.265rem ${(props) => props.theme.colors?.primary});
    > div {
      border: 1px solid ${(props) => props.theme.colors?.primary};
    }
  }
`
