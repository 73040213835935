import styled from 'styled-components'
import { DEVICE } from '@/utils/constants/breakpoints'

export const Container = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.875rem 0 0;
  position: relative;
  overflow: hidden;
  background: ${(props) => props.theme.colors?.base800};
  min-height: max-content;

  @media ${DEVICE.tablet} {
    padding: 11.875rem 0 0;
  }

  > nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 1500px;
    @media ${DEVICE.tablet} {
      padding: 0 2rem;
    }

    h2 {
      font-size: 2.625rem;
      font-family: ${(props) => props.theme.fonts?.second};
      margin: 0;
      margin-bottom: 1rem;
      z-index: 20;
      padding: 0 2rem;
    }

    > p {
      font-size: 1.125rem;
      font-family: ${(props) => props.theme.fonts?.primary};
      color: ${(props) => props.theme.colors?.base800};
      margin: 0;
      max-width: 576px;
      text-align: center;
      z-index: 20;
      padding: 0 2rem;

      @media ${DEVICE.tablet} {
        max-width: 576px;
      }
    }

    &::before {
      content: '';
      height: 100%;
      left: 0;
      top: -15%;
      position: absolute;
      transform-origin: right;
      transform: skewY(13deg);
      width: 100%;
      background: ${(props) => props.theme.colors?.base50};
      @media ${DEVICE.laptopL} {
        top: -7%;
      }

      @media ${DEVICE.desktopS} {
        top: -1%;
      }

      @media ${DEVICE.desktopL} {
        top: 0%;
      }
    }
  }
`
