import styled from 'styled-components'
import { DEVICE } from '@/utils/constants/breakpoints'

export const Container = styled.section`
  width: 100%;
  padding: 3rem 1rem 5rem;

  @media ${DEVICE.tablet} {
    padding: 7.75rem 2rem;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  height: 100%;
  min-height: max-content;

  > span {
    background: ${(props) => props.theme.colors?.primary};
    color: ${(props) => props.theme.colors?.base50};
    font-family: ${(props) => props.theme.fonts?.second};
    font-size: 1.125rem;
    font-weight: bold;
    padding: 5px 15px;
    border-radius: 5px;
  }

  > h3 {
    color: ${(props) => props.theme.colors?.base700};
    font-family: ${(props) => props.theme.fonts?.second};
    font-size: 2.625rem;
    font-weight: bold;
    margin: 1.563rem 0;
    max-width: 304px;
    letter-spacing: -1.26px;

    @media ${DEVICE.tablet} {
      margin: 1.563rem 0 3.063rem;
      max-width: max-content;
    }
  }

  > nav {
    width: 100%;
    display: flex;
    text-align: left;
    @media ${DEVICE.mobileL} {
      max-width: 1000px;
    }

    @media ${DEVICE.laptopL} {
      max-width: 1300px;
    }
  }

  padding: 14.75rem 0rem 20.75rem;

  @media ${DEVICE.tablet} {
    padding: 7.75rem 2rem;
  }

  > h3 {
    max-width: 100%;
  }

  > nav {
    min-width: 100%;
    min-height: 800px;
    padding: 0 3rem;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    position: relative;

    @media ${DEVICE.tablet} {
      padding: 3.75rem 0;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-auto-flow: row;

      column-gap: 2rem;
      row-gap: 2.5rem;
      width: 100%;
      max-width: 1200px;
      list-style: none;

      @media ${DEVICE.mobileL} {
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
      }

      @media ${DEVICE.tablet} {
        column-gap: 5.625rem;
      }
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      width: 100%;
      height: 80px;

      transition: all ease-in-out 0.2s;

      span {
        width: 100% !important;
        height: 100% !important;
      }

      &:hover,
      &:focus {
        padding-bottom: 2rem;
      }
    }
  }
`
