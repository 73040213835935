const title = 'A Software House do seu negócio | Inovan.do'
const description =
  'Precisando de uma solução digital? Somos especialistas em desenvolver produtos digitais de forma simples e ágil. Conheça nossos serviços.'
const canonical = 'https://website.inovan.do/'

export const SEO = {
  title,
  description,
  canonical,
  openGraph: {
    type: 'website',
    url: 'https://website.inovan.do/',
    title,
    description,
    images: [
      {
        url: './src/assets/images/logo_inovando_completa.svg',
        alt: title,
        width: 1280,
        height: 720,
      },
    ],
  },
}
