import React from 'react'
import Image, { StaticImageData } from 'next/image'

import { Container, Card, ButtonLeft, ButtonRight } from './styles'

import { Keyboard, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi'

import { Tag } from '@/components/atoms/Tag'

interface IItem {
  image: {
    src: StaticImageData
    alt: string
  }
  text: string
  tags: string[]
}

interface IFeedbackCarouselProps {
  items: IItem[]
  title: string
}

const BREAKPOINTS = {
  0: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  680: {
    slidesPerView: 2,
    spaceBetween: 30,
  },
  1000: {
    slidesPerView: 3,
    spaceBetween: 40,
  },
}

export function FeedbackCarousel({ items }: IFeedbackCarouselProps) {
  return (
    <Container data-testid="carousel">
      <Swiper
        loop
        watchOverflow
        breakpoints={BREAKPOINTS}
        mousewheel
        navigation={{
          prevEl: '#previous-button',
          nextEl: '#next-button',
        }}
        slidesPerView={3}
        spaceBetween={20}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Keyboard, Navigation, Pagination]}
      >
        {items.map((item, index) => {
          return (
            <SwiperSlide key={`${item.text} - ${index}`}>
              <Card>
                <header>
                  <Image
                    width={238}
                    height={66}
                    src={item.image.src}
                    alt={item.image.alt}
                  />
                </header>
                <p>{item.text}</p>
                <footer>
                  {item.tags.map((text, index) => (
                    <Tag key={`${text} - ${index}`} text={text} />
                  ))}
                </footer>
              </Card>
            </SwiperSlide>
          )
        })}

        <ButtonLeft
          id="previous-button"
          aria-label="Slide Anterior"
          data-message="Ir ao anterior"
        >
          <HiOutlineArrowLeft />
        </ButtonLeft>
        <ButtonRight
          id="next-button"
          aria-label="Próximo Slide"
          data-message="Ir ao próximo"
        >
          <HiOutlineArrowRight />
        </ButtonRight>
      </Swiper>
    </Container>
  )
}
