import React from 'react'

import { useLanguage } from '@/hooks/useLanguage'

import { AboutSection } from '@/components/organisms/AboutSection'
import { HomeSection } from '@/components/organisms/HomeSection'
import { CtaSection } from '@/components/organisms/CtaSection'
import { OurSolutionsSection } from '@/components/organisms/OurSolutionsSection'
import { TechnologiesSection } from '@/components/organisms/TechnologiesSection'
import { CasesSection } from '@/components/organisms/CasesSection'
import { TeamSection } from '@/components/organisms/TeamSection'
import { CostumerSatisfiedSection } from '@/components/organisms/CostumerSatisfiedSection'
import { CostumerFeedbacks } from '@/components/molecules/CostumerFeedbacks'

export const HomeTemplate = () => {
  const { content } = useLanguage()

  return (
    <>
      <HomeSection {...content.home} />
      <AboutSection {...content.about} />
      <CtaSection {...content.cta} />
      <OurSolutionsSection {...content.ourSolutions} />
      <TechnologiesSection {...content.technologies} />
      <CasesSection {...content.cases} />
      <TeamSection {...content.team} />
      <CostumerSatisfiedSection {...content.costumersSatisfied} />
      <CostumerFeedbacks {...content.feedbacks} />
    </>
  )
}
