import { DEVICE } from '@/utils/constants/breakpoints'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 1500px;
  position: absolute;

  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 3.875rem;

  @media ${DEVICE.tablet} {
    margin: 0;
  }

  .swiper {
    display: block;
    padding: 0 2rem;
  }

  .swiper-slide {
    height: initial;
  }

  h2 {
    text-align: center;
    align-items: center;
    font-size: 2.625rem;
    font-family: ${(props) => props.theme.fonts?.second};
    margin: 0;
    margin-bottom: 1rem;
    z-index: 20;
    padding: 0 2rem;
    margin-bottom: 3.063rem;
  }
`

export const Card = styled.div`
  background: ${(props) => props.theme.colors?.base50};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  border: 1px solid #97b8c8;
  padding: 3.25rem 2.875rem 2.5rem;
  flex: 1;
  height: 100%;

  @media ${DEVICE.tablet} {
    padding: 3.125rem 3.75rem 2.25rem;
    margin-right: 1rem;
  }

  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  p {
    font-family: ${(props) => props.theme.fonts?.primary};
    color: ${(props) => props.theme.colors?.base800};
    font-size: 1.125rem;
    margin-bottom: 2.188rem;
  }

  footer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;
  }
`

export const Button = styled.button`
  height: 2.5rem;
  width: 2.5rem;

  @media ${DEVICE.tablet} {
    height: 4rem;
    width: 4rem;
  }

  color: ${(props) => props.theme.colors?.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: ${(props) => props.theme.colors?.base50};
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  box-shadow: 0px 7px 14px #afafaf8a;
  top: 50%;
`

export const ButtonRight = styled(Button)`
  right: 0;
  transform: translate(-25%, -75%);
`

export const ButtonLeft = styled(Button)`
  transform: translate(10%, -75%);
  left: 0;
`
