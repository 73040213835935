import styled from 'styled-components'

import { DEVICE } from '@/utils/constants/breakpoints'

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  color: ${(props) => props.theme.colors?.base50};
  font-size: 3rem;
  text-align: center;

  width: 100%;

  picture {
    min-height: 313px;
  }

  position: relative;

  @media ${DEVICE.tablet} {
    background: #18316e;
    align-items: center;
    justify-content: center;
  }

  > div.hero-article {
    background-color: ${(props) => props.theme.colors?.base300};

    z-index: 1;
    width: 100%;
    max-width: 1920px;

    @media ${DEVICE.tablet} {
      display: block;
      background: transparent;
      padding-left: 2rem;
      position: absolute;
    }

    @media ${DEVICE.laptop} {
      padding-left: 5rem;
    }
  }

  article {
    max-width: 18.75rem;
    margin: 1.875rem 1.438rem 1.625rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: center;
    color: ${(props) => props.theme.colors?.base800};

    gap: 2.063rem;

    @media ${DEVICE.tablet} {
      max-width: 18rem;
      color: ${(props) => props.theme.colors?.base50};
    }

    @media ${DEVICE.laptopL} {
      max-width: 29.688rem;
    }

    h1 {
      margin: 0;
      font-family: ${(props) => props.theme.fonts?.second};
      line-height: 3rem;
      font-size: 3.25rem;
      margin-bottom: 0.813rem;
      font-weight: 600;

      strong {
        font-weight: bold;
      }

      @media ${DEVICE.tablet} {
        line-height: 48px;
        font-size: 2.625rem;
      }

      @media ${DEVICE.laptopL} {
        line-height: 74px;
        font-size: 4.688rem;
      }
    }

    picture {
      display: block;
      @media ${DEVICE.tablet} {
        display: none;
      }
    }

    p {
      margin: 0;
      font-weight: 600;
      font-family: ${(props) => props.theme.fonts?.primary};
      font-size: 1.313rem;
      line-height: 2rem;
      letter-spacing: -0.09px;
      max-width: 340px;

      @media ${DEVICE.tablet} {
        font-size: 1.265rem;
      }

      @media ${DEVICE.laptopL} {
        font-size: 1.5rem;
      }
    }
  }
`

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  height: 313px;

  @media ${DEVICE.tablet} {
    height: auto;
  }
`
