import React from 'react';
import Image, { StaticImageData } from 'next/image';

import imgLightbulb from '@/assets/icons/lightbulb_card.svg';

import { Container } from './styles';

import { Tag } from '@/components/atoms/Tag';

interface ICard {
  image: {
    src: StaticImageData;
    alt: string;
  };
  text: {
    title: {
      bold: string;
      body: string;
    };
    body: string;
    tags: string[];
    suggestion: string;
  };
}

interface IServiceCardProps {
  item: ICard;
}

import { sanitize } from 'isomorphic-dompurify'

export function ServiceCard({ item }: IServiceCardProps) {
  return (
    <Container data-testid="service-card">
      <div>
        <figure>
          <Image
            src={item.image.src}
            width={65}
            height={65}
            alt={item.image.alt}
          />
        </figure>

        <article>
          <h3>
            <strong>{item.text.title.bold} {item.text.title.body}</strong>
          </h3>
          <article dangerouslySetInnerHTML={{ __html: sanitize(item.text.body) }} />

          <div>
            {item.text.tags.map((tag, index) => (
              <Tag key={`${tag} - ${index}`} text={tag} />
            ))}
          </div>
        </article>
      </div>

      <footer>
        <figure>
          <Image
            src={imgLightbulb}
            width={29}
            height={29}
            alt="Imagem de lâmpada"
          />
        </figure>

        <p>{item.text.suggestion}</p>
      </footer>
    </Container >
  );
}
