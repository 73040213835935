import styled from 'styled-components'

import { DEVICE } from '@/utils/constants/breakpoints'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  @media ${DEVICE.laptop} {
    flex-direction: row;
  }
`

export const InfoContent = styled.div`
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  padding: 5.063rem 1.094rem 6.438rem;

  article {
    max-width: 571px;

    h2 {
      text-align: left;
      letter-spacing: -1.26px;
      font-size: 2.625rem;
      font-family: ${(props) => props.theme.fonts.second};
      line-height: 3.125rem;
      max-width: 451px;
    }

    p {
      font-size: 1rem;
      font-family: ${(props) => props.theme.fonts.primary};
      line-height: 1.625rem;
    }
  }
`

export const CallToAction = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${(props) => props.theme.colors?.base800};
  padding: 6.125rem 2rem;

  @media ${DEVICE.laptopL} {
    padding: 8.125rem 2rem;
  }

  > div {
    max-width: 260px;
    z-index: 20;

    p {
      font-family: ${(props) => props.theme.fonts?.second};
      font-size: 1.5rem;
      color: ${(props) => props.theme.colors?.base50};
      margin: 3.313rem 0 2rem;
      max-width: 431px;
    }

    a {
      padding: 0.938rem 2.063rem;
      background: ${(props) => props.theme.colors?.primary};
      font-size: 1.125rem;
      color: ${(props) => props.theme.colors?.base50};
      text-decoration: none;
      font-family: ${(props) => props.theme.fonts?.primary};
      font-weight: 600;

      &:hover {
        cursor: pointer;
      }
    }

    @media ${DEVICE.tablet} {
      max-width: 893px;

      p {
        font-size: 1.875rem;
      }
    }
  }
`
