import styled from 'styled-components'

export const Container = styled.span`
  width: max-content;
  background: ${(props) => props.theme.colors?.primary};
  padding: 5px 12px;
  color: ${(props) => props.theme.colors?.base50};
  font-size: 0.688rem;
  font-family: ${(props) => props.theme.fonts?.primary};
  font-weight: 600;
  border-radius: 9999px;
  white-space: nowrap;
`
