import React, { createRef, useCallback, useLayoutEffect, useMemo, useState } from 'react'

import Image, { StaticImageData } from 'next/image'

import { Container, SliderItem } from './styles'

import { Tag } from '@/components/atoms/Tag'
import { CasesSliderNavigation } from '@/components/molecules/CasesSliderNavigation'

import { Keyboard, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { useMediaQuery } from 'usehooks-ts'

interface IItem {
  banner: {
    src: StaticImageData
    alt: string
  }
  logo: {
    src: StaticImageData
    alt: string
  }
  text: string
  tags: string[]
}

interface IItems {
  items: IItem[]
}

interface ISliderRef {
  current: HTMLDivElement
}

export function CasesSlider({ items }: IItems) {
  const [indexCurrentSlider, setIndexCurrentSlider] = useState(0)
  const [elRefs, setElRefs] = useState<Array<ISliderRef>>([]);
  const [positioning, setPositioning] = useState({})
  const matches = useMediaQuery('(max-width: 768px)')

  const handleChangeSlider = (activeIndex: number) => {
    if (activeIndex === items.length + 1) setIndexCurrentSlider(1)
    else if (activeIndex === 0) setIndexCurrentSlider(items.length)
    else setIndexCurrentSlider(activeIndex)
  }

  const arrLength = items.length


  const mostTallParagraph = useMemo(() => {
    if (elRefs.length) {
      const mostLarge = elRefs.reduce((acc, ref) => {
        if (ref.current && ref.current?.offsetHeight > acc.current.offsetHeight) return ref
        return acc
      });
      return mostLarge
    }

    return null
  }, [elRefs])

  const createPositioning = useCallback((mostTallParagraph: ISliderRef) => {
    return matches ? {} : {
      top: mostTallParagraph ? mostTallParagraph?.current?.offsetTop + mostTallParagraph?.current?.offsetHeight * 1.25 : 0,
      left: mostTallParagraph ? mostTallParagraph?.current?.offsetLeft : 0,
    }
  }, [matches])

  useLayoutEffect(() => {
    setElRefs((elRefs) =>
      Array(arrLength)
        .fill(null)
        .map((_, i) => elRefs[i] || createRef<HTMLParagraphElement>()),
    );

    mostTallParagraph && setPositioning(createPositioning(mostTallParagraph))
  }, [arrLength, mostTallParagraph, createPositioning]);

  return (
    <Container data-testid="slider">
      <Swiper
        mousewheel
        onSlideChange={({ activeIndex }) => handleChangeSlider(activeIndex)}
        loop
        navigation={{
          prevEl: '#cases-previous-button',
          nextEl: '#cases-next-button',
        }}
        slidesPerView={1}
        spaceBetween={20}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Keyboard, Navigation, Pagination]}
      >
        <CasesSliderNavigation
          style={positioning}
          currentOnScreen={indexCurrentSlider}
          sliderItemsNumber={items.length}
        />
        {items.map((item, index) => {
          return (
            <SwiperSlide key={`${item.text} - ${index}`}>
              <SliderItem>
                <figure>
                  <Image
                    src={item.banner.src}

                    objectFit='contain'
                    alt={item.banner.alt}
                  />
                </figure>
                <div ref={elRefs[index]}>
                  <Image
                    src={item.logo.src}
                    objectFit="fill"
                    alt={item.logo.alt}
                  />
                  <p>{item.text}</p>

                  <div>
                    {item.tags.map((tag, index) => (
                      <Tag key={`${tag} - ${index}`} text={tag} />
                    ))}
                  </div>
                </div>
              </SliderItem>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Container>
  )
}
