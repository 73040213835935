import styled from 'styled-components'
import { DEVICE } from '@/utils/constants/breakpoints'

export const Container = styled.nav`
  display: flex;
  width: 100%;
  height: max-content;

  z-index: 20;

  @media ${DEVICE.tablet} {
    height: 90vh;
  }

  .swiper {
    padding: 86px 0 0;

    @media ${DEVICE.tablet} {
      padding: 86px 0 200px;
    }
  }
`

export const SliderItem = styled.div`
  width: 100%;
  height: 100%;
  flex: none;
  scroll-snap-align: start;

  display: flex;
  justify-content: start;
  flex-direction: column-reverse;

  @media ${DEVICE.tablet} {
    align-items: center;
    flex-direction: row;
    justify-content: center;
    figure {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 40px;
      margin-inline-end: 40px;
    }
  }

  figure {
    margin: 3.75rem 0;
    max-width: 600px;
    > span {
      height: 100% !important;
    }

    @media ${DEVICE.tablet} {
      height: 100%;
      margin: 0;
    }
  }

  p {
    text-align: left;
    margin: 1.875rem 0 0.938rem;
    max-width: 268px;
    font-size: 1.125rem;
    font-family: ${(props) => props.theme.fonts?.primary};
    color: ${(props) => props.theme.colors?.base800};
    text-align: left;
    z-index: 20;

    @media ${DEVICE.tablet} {
      max-width: 380px;
    }
  }

  > div {
    padding: 0 2rem;
    margin-top: 1.813rem;

    @media ${DEVICE.tablet} {
      margin: 0 0 100px 0;
    }
  }

  > div > div:first-of-type {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
`
