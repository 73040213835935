import React from 'react'

import { Container } from './styles'

import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi'

interface CasesSliderNavigationProps extends React.HTMLAttributes<HTMLDivElement> {
  currentOnScreen: number
  sliderItemsNumber: number
}

export function CasesSliderNavigation({
  currentOnScreen,
  sliderItemsNumber,
  ...rest
}: CasesSliderNavigationProps) {
  return (
    <Container {...rest}>
      <button id="cases-previous-button" aria-label="Voltar pro slide anterior">
        <HiOutlineArrowLeft />
      </button>
      <span>
        {currentOnScreen} / {sliderItemsNumber}
      </span>
      <button id="cases-next-button" aria-label="Ir para o próximo slide">
        <HiOutlineArrowRight />
      </button>
    </Container>
  )
}
