import React from 'react'
import { HomeTemplate } from '@/components/templates/HomeTemplate'
import { DefaultSeo } from 'next-seo'
import { SEO } from '@/../next-seo.config'
import { GetStaticProps } from 'next'
import { MainPage } from '@/layouts/MainPage'
import CookieConsent from 'react-cookie-consent'
import Link from 'next/link'
import { useRouter } from 'next/router'
import enUS from '@/lang/en-US/cookies-consent.json'
import ptBR from '@/lang/pt-BR/cookies-consent.json'

const content = {
  'en-US': enUS,
  'pt-BR': ptBR
}

type Language = keyof typeof content

interface IContentCookiesProps {
  locale: Language
}

const ContentCookies = ({ locale }: IContentCookiesProps) => {
  const language = content[locale]

  return (
    <div style={{ maxWidth: '900px' }}>
      <p>
        {language.text}{' '}
        <Link href="/privacy-policy">
          <a>{language.policy}</a>
        </Link>.
      </p>
    </div>
  )
}

const Home = () => {
  const { locale } = useRouter()
  return (
    <MainPage>
      <DefaultSeo {...SEO} />
      <HomeTemplate />
      <CookieConsent
        location="bottom"
        buttonText={content[locale as Language].button}
        cookieName="inovando.cookie.consent"
        style={{ background: "#fff", color: '#000', borderTop: '1px solid #cecece', fontFamily: "'Montserrat', sans-serif" }}
        buttonStyle={{ color: "#fff", fontSize: "18px", background: '#1fa1e3', fontWeight: 500, borderRadius: '8px', padding: '8px 16px' }}
        expires={150}>
        <ContentCookies locale={locale as Language} />
      </CookieConsent>
    </MainPage>
  )
}

export default Home

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
  }
}
