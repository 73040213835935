import React from 'react';

import Image from 'next/image';

import { SECTION_PATHS } from '@/utils/constants/sectionPaths';

import { LanguageContent } from '@/hooks/useLanguage';

import { Container } from './styles';
import { casesLanguage } from '@/utils/constants/silderContent';

import { CasesSlider } from '../CasesSlider';

import casesBannerImg from '@/assets/images/cases_banner_mask@2.png'
import { useRouter } from 'next/router';

export function CasesSection({ mainText, subText }: LanguageContent['cases']) {
  const { locale } = useRouter();
  const { CASES } = casesLanguage(locale);

  return (
    <Container data-testid="cases-section" id={SECTION_PATHS.cases}>
      <Image
        className="background"
        src={casesBannerImg}
        alt="HomePage Illustration"
        layout="fill"
        objectFit="cover"
        objectPosition="left"
        loading="lazy"
      />
      <nav>
        <h2>{mainText}</h2>
        <p>{subText}</p>

        <CasesSlider items={CASES} />
      </nav>
    </Container>
  );
}
