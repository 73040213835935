import React from 'react';

import { Container } from './styles';

interface ITagProps extends React.HTMLAttributes<HTMLSpanElement> {
  text: string;
}

export function Tag({ text, ...rest }: ITagProps) {
  return <Container data-testid="tag" {...rest}>{text}</Container>;
}
