import styled from 'styled-components'

import { DEVICE } from '@/utils/constants/breakpoints'

export const Container = styled.section`
  position: relative;
  width: 100%;
  padding: 1.563rem 1.313rem;
  border-bottom: 1px solid ${(props) => props.theme.colors?.base200};

  @media ${DEVICE.tablet} {
    padding: 7.75rem 1.313rem;
  }

  > div {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    > span {
      background: ${(props) => props.theme.colors?.primary};
      color: ${(props) => props.theme.colors?.base50};
      font-family: ${(props) => props.theme.fonts?.second};
      font-size: 1.125rem;
      font-weight: bold;
      padding: 5px 15px;
      border-radius: 5px;
      z-index: 1;
    }

    > h2 {
      color: ${(props) => props.theme.colors?.base700};
      font-family: ${(props) => props.theme.fonts?.second};
      font-size: 2.625rem;
      font-weight: bold;
      z-index: 1;
      max-width: 19.125rem;
      letter-spacing: -1.26px;
      line-height: 3.125rem;
      margin: 1.563rem 0 0;

      @media ${DEVICE.tablet} {
        max-width: max-content;
      }
    }

    > p {
      font-size: 1.125rem;
      font-family: ${(props) => props.theme.fonts?.primary};
      color: ${(props) => props.theme.colors?.base800};
      margin: 0;
      max-width: 576px;
      text-align: center;
      z-index: 20;
      padding: 0 2rem;
      margin: 1.563rem 0 0.875rem 0;
      @media ${DEVICE.tablet} {
        max-width: max-content;
        margin: 1.563rem 0 4.5rem 0;
      }

      @media ${DEVICE.tablet} {
        max-width: 576px;
      }
    }

    > nav {
      z-index: 1;
      width: 100%;
      max-width: 1650px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      align-items: flex-start;
      justify-content: center;
      gap: 0.938rem;
    }
  }

  .background {
    object-fit: center !important;
  }
`
