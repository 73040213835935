import React from 'react';

import { SECTION_PATHS } from '@/utils/constants/sectionPaths';

import { LanguageContent } from '@/hooks/useLanguage';

import { Container } from './styles';

import { TEAM } from '@/utils/constants/teamGrid';

import { TeamGrid } from '../TeamGrid';
import { useIsClient } from 'usehooks-ts';

export function TeamSection({ tag, title, subText, cta }: LanguageContent['team']) {
  const isClient = useIsClient();

  return (
    <Container data-testid="team-section" id={SECTION_PATHS.team}>
      <span>{tag}</span>
      <h2>{title}</h2>
      <p>{subText} <a href='mailto:contato@inovan.do?CC=Trabalhe na Inovan.do'>{cta}</a></p>

      <nav>
        {isClient && <TeamGrid items={TEAM} />}
      </nav>
    </Container>
  );
}
