import imgGraphic from '@/assets/icons/noun_graph.svg'
import imgTechnology from '@/assets/icons/noun_tech.svg'
import imgTeam from '@/assets/icons/noun_team.svg'

import cardPtBr from '@/lang/pt-BR/cards.json'
import cardEnUS from '@/lang/en-US/cards.json'

export const cardContentLanguage = (lang = 'pt-BR') => {
  const content = lang === 'pt-BR' ? cardPtBr : cardEnUS

  const CARD_CONTENT = [
    {
      image: {
        src: imgGraphic,
        alt: 'Imagem de Gráfico',
      },
      ...content.solution,
    },
    {
      image: {
        src: imgTechnology,
        alt: 'Imagem de Peça de Tecnologia',
      },
      ...content.project,
    },
    {
      image: {
        src: imgTeam,
        alt: 'Imagem de Equipe',
      },
      ...content.partner,
    },
  ]
  return { CARD_CONTENT }
}
