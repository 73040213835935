import { DEVICE } from '@/utils/constants/breakpoints'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  --s: 100px; /* size  */
  --m: 4px; /* space */
  --r: calc(var(--s) + 5 * var(--m) - 2px);

  @media (min-width: 376px) {
    --s: 115px; /* size  */
    --m: 4px; /* space */
    --r: calc(var(--s) + 5 * var(--m) - 3px);
  }

  @media ${DEVICE.tablet} {
    --s: 130px; /* size  */
    --r: calc(var(--s) + 4 * var(--m) - 2px);
  }

  @media ${DEVICE.laptopL} {
    --s: 175px; /* size  */
    --r: calc(var(--s) + 4 * var(--m) - 2px);
  }

  font-size: 0; /*disable white space between inline block element */
  &::before {
    content: '';
    width: calc(var(--s) / 2 + var(--m));
    float: left;
    height: 140%;
    shape-outside: repeating-linear-gradient(
      #0000 0 calc(var(--r) - 3px),
      #000 0 var(--r)
    );
  }
`

interface IContainerItemProps {
  isEmpty: boolean
  isBlue?: boolean
}

export const ContainerItem = styled.div<IContainerItemProps>`
  width: var(--s);
  margin: var(--m);
  height: var(--s);

  display: inline-block;
  margin-bottom: calc(var(--m) - var(--s) * 0.5);
  transition: all 200ms ease-in-out;
  position: relative;
  z-index: 1;

  picture {
    width: 100%;
    height: 100%;
    margin: 0;
    position: absolute;

    .grid-image {
      margin: 0;
      width: 100%;
      height: 100%;
    }

    span {
      display: flex;
    }
  }

  > div {
    width: var(--s);
    margin: var(--m);
    height: var(--s);
    display: inline-block;
    margin-bottom: calc(var(--m) - var(--s) * 0.5);
    position: relative;
    font-size: initial;

    clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
    background: ${(props) => props.theme.colors?.primary};

    @media ${DEVICE.laptop} {
      opacity: 0.5;
    }

    transition: opacity 200ms ease-in-out;

    > div {
      transition: all 200ms ease-in-out;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: rgba(31, 161, 227, 0.6);
      opacity: 1;

      color: ${(props) => props.theme.colors?.base50};
      text-align: center;

      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        font-size: 0.725rem;
        @media ${DEVICE.laptopL} {
          font-size: 1rem;
        }
      }

      span:first-of-type {
        font-size: 1rem;
        line-height: 1.265rem;
        font-family: ${(props) => props.theme.fonts?.second};
        font-weight: bold;
        margin: 0;
        width: 100%;
        max-width: 100px;

        @media ${DEVICE.laptopL} {
          font-size: 1.375rem;
        }
      }

      span {
        opacity: 0;
      }

      p {
        opacity: 0;
        font-size: 0.725rem;
        font-family: ${(props) => props.theme.fonts?.primary};
      }

      @media ${DEVICE.laptop} {
        background: none;
        opacity: 0;

        span:first-of-type,
        span {
          opacity: 1;
        }
      }
    }
  }

  &:hover {
    z-index: 10;
    transform: scale(1.2);
    filter: drop-shadow(0px 3px 36px #1fa1e3);

    > div {
      opacity: 1;

      > div {
        @media ${DEVICE.laptop} {
          background: rgba(31, 161, 227, 0.6);
          opacity: 1;
        }
      }
    }
  }

  ${(props) =>
    props.isEmpty &&
    css`
      > div {
        opacity: 1;
      }

      position: relative;

      &::after {
        content: '';
        width: calc(var(--s) - 4px);
        position: absolute;
        height: calc(var(--s) - 4px);
        display: inline-block;
        font-size: initial;
        top: 6px;
        left: 6px;

        clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
        background: ${(props) => props.theme.colors?.base50};
      }

      &:hover {
        transform: scale(1);
        filter: none;

        > div > div {
          display: none;
        }
      }
    `}

  ${(props) =>
    props.isBlue &&
    css`
      @media ${DEVICE.tablet} {
        > div {
          opacity: 1;
        }

        position: relative;

        &::after {
          opacity: 0.5;
        }
      }
    `}
`

export const WorkWithUs = styled.div`
  opacity: 1 !important;
  cursor: pointer;
  line-height: 1.265rem;

  > div {
    opacity: 1 !important;
    font-size: 0.875rem;
    font-family: ${(props) => props.theme.fonts?.second};
    font-weight: bold;
    max-width: 120px;

    @media ${DEVICE.laptop} {
      font-size: 1.265rem;
    }
  }
`
