import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  z-index: 20;
  display: flex;
  align-items: center;
  margin-top: 2.813rem;
  font-size: 1.125rem;
  font-weight: bold;
  gap: 1.563rem;
  top: 0;
  padding: 0 2rem;
  height: fit-content;

  button {
    border: 0;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover,
    &:focus {
      color: ${(props) => props.theme.colors?.primary};
    }
  }
`
