import TahnamaoBanner from '@/assets/images/tahnamao_banner/tahnamao_banner@1.png'
import TahnamaoLogo from '@/assets/images/tahnamao_logo/tahnamao_logo@1.png'

import BellinatiBanner from '@/assets/images/bellinati_banner/bellinati_banner@1.png'
import BellinatiLogo from '@/assets/images/bellinati_logo/bellinati_logo@1.png'

import OiroBanner from '@/assets/images/oiro_banner/oiro_banner@1.png'
import OiroLogo from '@/assets/images/oiro_logo/oiro_logo@1.png'

import AvmBanner from '@/assets/images/avm_banner/avm_banner@1.png'
import AvmLogo from '@/assets/images/avm_logo/avm_logo@1.png'

import TaclaBanner from '@/assets/images/tacla_banner/tacla_banner@1.png'
import TaclaLogo from '@/assets/images/tacla_logo/tacla_logo@1.png'

import SunhubBanner from '@/assets/images/sunhub_banner/sunhub_banner@1.png'
import SunhubLogo from '@/assets/images/sunhub_logo/sunhub_logo@1.png'

import VenatusBanner from '@/assets/images/venatus_banner/venatus_banner@1.png'
import VenatusLogo from '@/assets/images/venatus_logo/venatus_logo@1.png'

import AtlanticaBanner from '@/assets/images/atlantica_banner/atlantica_banner@1.png'
import AtlanticaLogo from '@/assets/images/atlantica_logo/atlantica_logo@1.png'

import casesPtBr from '@/lang/pt-BR/cases.json'
import casesEnUs from '@/lang/en-US/cases.json'

export const casesLanguage = (lang = 'pt-BR') => {
  const content = lang === 'pt-BR' ? casesPtBr : casesEnUs

  const CASES = [
    {
      banner: {
        src: TahnamaoBanner,
        alt: 'Tahnamão',
      },
      logo: {
        src: TahnamaoLogo,
        alt: 'Logo do Tahnamão',
      },
      ...content.tahnamao,
    },
    {
      banner: {
        src: BellinatiBanner,
        alt: 'Bellinati Peres',
      },
      logo: {
        src: BellinatiLogo,
        alt: 'Logo da Bellinati Peres',
      },
      ...content.bellinati,
    },
    {
      banner: {
        src: OiroBanner,
        alt: 'Oiro',
      },
      logo: {
        src: OiroLogo,
        alt: 'Logo da Oiro',
      },
      ...content.oiro,
    },

    {
      banner: {
        src: AtlanticaBanner,
        alt: 'Atlantica',
      },
      logo: {
        src: AtlanticaLogo,
        alt: 'Logo da Atlantica',
      },
      ...content.atlantica,
    },
    {
      banner: {
        src: TaclaBanner,
        alt: 'Tacla',
      },
      logo: {
        src: TaclaLogo,
        alt: 'Logo da Tacla',
      },
      ...content.tacla,
    },
    {
      banner: {
        src: AvmBanner,
        alt: 'Associação da Vila Militar',
      },
      logo: {
        src: AvmLogo,
        alt: 'Logo da Associação da Vila Militar',
      },
      ...content.avm,
    },
    {
      banner: {
        src: SunhubBanner,
        alt: 'Sunhub',
      },
      logo: {
        src: SunhubLogo,
        alt: 'Logo da Sunhub',
      },
      ...content.sunhub,
    },
    {
      banner: {
        src: VenatusBanner,
        alt: 'Venatus',
      },
      logo: {
        src: VenatusLogo,
        alt: 'Logo da Venatus',
      },
      ...content.venatus,
    },
  ]

  return { CASES }
}
