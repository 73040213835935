import boticarioImg from '@/assets/images/boticario_logo/boticario_logo@2.png'
import orionImg from '@/assets/images/orion_logo/orion_logo@2.png'
import clinicaRImg from '@/assets/images/clinicar_logo/clinicar_logo@2.png'
import oiroImg from '@/assets/images/oiro_logo/oiro_logo@2.png'
import paranaBancoImg from '@/assets/images/paranabanco_logo/paranabanco_logo@2.png'
import vetorLogImg from '@/assets/images/vetorlog_logo/vetorlog_logo@2.png'
import agilImg from '@/assets/images/agil_logo/agil_logo@2.png'
import taclaImg from '@/assets/images/tacla_logo/tacla_logo@2.png'
import tahnamaoImg from '@/assets/images/tahnamao_logo/tahnamao_logo@2.png'
import octoshopImg from '@/assets/images/octoshop_logo/octoshop_logo@2.png'
import jetlinkImg from '@/assets/images/jetlink_logo/jetlink_logo@2.png'
import atlanticaImg from '@/assets/images/atlantica_logo/atlantica_logo@2.png'
import venatusImg from '@/assets/images/venatus_logo/venatus_logo@2.png'
import bellinatiImg from '@/assets/images/bellinati_logo/bellinati_logo@2.png'
import unilImg from '@/assets/images/unil_logo/unil_logo@2.png'
import nexusImg from '@/assets/images/nexus_logo/nexus_logo@2.png'
import soluczImg from '@/assets/images/solucz_logo/solucz_logo@2.png'
import avmImg from '@/assets/images/avm_logo/avm_logo@2.png'
import sunhubImg from '@/assets/images/sunhub_logo/sunhub_logo@2.png'
import obraFacilImg from '@/assets/images/obrafacil_logo/obrafacil_logo@2.png'

import feedbacksPtBr from '@/lang/pt-BR/feedbacks.json'
import feedbacksEnUs from '@/lang/en-US/feedbacks.json'

export const SATISFIED_COSTUMERS = [
  {
    src: boticarioImg,
    alt: 'Grupo Boticário',
  },
  {
    src: orionImg,
    alt: 'Orion - Certificado digital',
  },
  {
    src: clinicaRImg,
    alt: 'Clínica R',
  },
  {
    src: oiroImg,
    alt: 'Oiro',
  },
  {
    src: paranaBancoImg,
    alt: 'ParanáBanco',
  },
  {
    src: vetorLogImg,
    alt: 'VetorLog - Inteligência em medições',
  },
  {
    src: agilImg,
    alt: 'Agil Consulta',
  },
  {
    src: taclaImg,
    alt: 'Tacla Shopping',
  },
  {
    src: tahnamaoImg,
    alt: 'Tahnamão!',
  },
  {
    src: octoshopImg,
    alt: 'Octoshop',
  },
  {
    src: jetlinkImg,
    alt: 'Jetlink Peças',
  },
  {
    src: atlanticaImg,
    alt: 'Atlântica - Equipamentos de gastronomia',
  },
  {
    src: venatusImg,
    alt: 'Venatus',
  },
  {
    src: bellinatiImg,
    alt: 'Bellinati Peres',
  },
  {
    src: unilImg,
    alt: 'Unil',
  },
  {
    src: nexusImg,
    alt: 'Nexus',
  },
  {
    src: soluczImg,
    alt: 'Solucz - Soluções Inteligentes',
  },
  {
    src: avmImg,
    alt: 'Associação da Vila Militar',
  },
  {
    src: sunhubImg,
    alt: 'Sunhub',
  },
  {
    src: obraFacilImg,
    alt: 'Obra Fácil',
  },
]

export const feedbacksLanguage = (lang = 'pt-BR') => {
  const content = lang === 'pt-BR' ? feedbacksPtBr : feedbacksEnUs

  const FEEDBACKS = [
    {
      image: {
        src: vetorLogImg,
        alt: 'VetorLog',
      },
      ...content.vetorlog,
    },
    {
      image: {
        src: bellinatiImg,
        alt: 'Bellinati Peres',
      },
      ...content.bellinati,
    },
    {
      image: {
        src: boticarioImg,
        alt: 'Grupo Boticário',
      },
      ...content.boticario,
    },
  ]

  return { FEEDBACKS }
}
