import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import { Container, ContainerItem, WorkWithUs } from './styles'

interface IItem {
  name: string
  url: string
  occupation: string
  blue?: boolean
}

interface ITeamGridProps {
  items: IItem[]
}

export function TeamGrid({ items }: ITeamGridProps) {
  return (
    <Container data-testid="team-grid">
      <ContainerItem isEmpty={false}>
        <Link href="mailto:contato@inovan.do?CC=Trabalhe na Inovan.do">
          <WorkWithUs>
            <div>Trabalhe na Inovando</div>
          </WorkWithUs>
        </Link>
      </ContainerItem>
      {
        items.map((item, index) => (
          <ContainerItem isEmpty={!item.url} key={`${item.name} - ${index}`} isBlue={!!item.blue}>
            <div>
              {item.url && (
                <picture>
                  <Image className='grid-image' width={200} height={200} src={item.url} alt={item.name} />
                </picture>
              )}
              <div>
                <span>{item.name}</span>
                <span>{item.occupation}</span>
              </div>
            </div>
          </ContainerItem>
        ))
      }
    </Container >
  )
}
