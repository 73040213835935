import React from 'react';

import AboutIllustrator from '@/assets/images/about_illustration@2.webp'
import Image from 'next/image';

import { SECTION_PATHS } from '@/utils/constants/sectionPaths';

import {
  Container,
  MediumScreen,
  OnlyDesktop
} from './styles'

import { sanitize } from 'isomorphic-dompurify'

import type { LanguageContent } from '@/hooks/useLanguage';

export function AboutSection({ imageAlt, title, body }: LanguageContent['about']) {
  return (
    <Container data-testid="about-section" id={SECTION_PATHS.about}>
      <OnlyDesktop>
        <Image
          src={AboutIllustrator}
          width={914}
          height={678}
          objectFit="contain"
          alt={imageAlt}
          loading="lazy"
        />
      </OnlyDesktop>
      <article>
        <h2>{title}</h2>

        <div dangerouslySetInnerHTML={{ __html: sanitize(body.article) }} />
        <MediumScreen>
          <Image
            src={AboutIllustrator}
            width={668}
            height={704}
            objectFit="contain"
            alt={imageAlt}
            loading="lazy"
          />
        </MediumScreen>
      </article>
    </Container>
  );
}
