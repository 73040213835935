import React from 'react';

import Image from 'next/image';

import { Container } from './styles';

import { feedbacksLanguage } from '@/utils/constants/costumers';

import customersFeedbackImg from '@/assets/images/feedback_banner/feedback_banner@2.png'

import { FeedbackCarousel } from '@/components/organisms/FeedbackCarousel';
import { LanguageContent } from '@/hooks/useLanguage';

import { useIsClient } from 'usehooks-ts';

import { useRouter } from 'next/router';

export function CostumerFeedbacks({ title }: LanguageContent['feedbacks']) {
  const isClient = useIsClient();
  const { locale } = useRouter();
  const { FEEDBACKS } = feedbacksLanguage(locale);

  if (!isClient) return null;

  return (
    <Container data-testid="costumer-feedbacks-section">
      <Image
        className="background"
        src={customersFeedbackImg}
        alt="HomePage Illustration"
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        loading="lazy"
      />
      <FeedbackCarousel title={title} items={FEEDBACKS} />
    </Container>
  );
}
