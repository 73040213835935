import nodeImg from '@/assets/icons/nodejs.svg';
import reactImg from '@/assets/icons/reactjs.svg';
import reactNativeImg from '@/assets/icons/react-native.png';
import cypressImg from '@/assets/icons/cypress@1.png';
import nextImg from '@/assets/icons/nextjs@1.png';
import angularImg from '@/assets/icons/angular@1.png';
import phpImg from '@/assets/icons/php@1.png';
import awsImg from '@/assets/icons/aws.svg';
import vueImg from '@/assets/icons/vuejs.svg';
import appleImg from '@/assets/icons/apple-store.svg';
import playStoreImg from '@/assets/icons/google-play.svg';
import laravelImg from '@/assets/icons/laravel.svg';
import netMvcImg from '@/assets/icons/net-mvc@1.png';
import netCoreImg from '@/assets/icons/net-core@1.png';
import netFrameworkImg from '@/assets/icons/net-framework@1.png';
import csharpImg from '@/assets/icons/csharp@1.png';

export const TECHS = [
  {
    alt: 'NodeJS',
    src: nodeImg,
  },
  {
    alt: 'ReactJS',
    src: reactImg,
  },
  {
    alt: 'React Native',
    src: reactNativeImg,
  },
  {
    alt: 'Cypress.js',
    src: cypressImg,
  },
  {
    alt: 'NextJS',
    src: nextImg,
  },
  {
    alt: 'Angular',
    src: angularImg,
  },
  {
    alt: 'PHP',
    src: phpImg,
  },
  {
    alt: 'AWS',
    src: awsImg,
  },
  {
    alt: 'VueJS',
    src: vueImg,
  },
  {
    alt: 'Apple',
    src: appleImg,
  },
  {
    alt: 'Android',
    src: playStoreImg,
  },
  {
    alt: 'Laravel',
    src: laravelImg,
  },
  {
    alt: '.NET MVC',
    src: netMvcImg,
  },
  {
    alt: '.NET Core',
    src: netCoreImg,
  },
  {
    alt: '.NET Framework',
    src: netFrameworkImg,
  },
  {
    alt: 'C#',
    src: csharpImg,
  },
];
