import React from 'react'

import Image from 'next/image'

import { SECTION_PATHS } from '@/utils/constants/sectionPaths'

import { LanguageContent } from '@/hooks/useLanguage'

import ourSolutionsBannerImg from '@/assets/images/our_solutions_banner.png'

import { Container } from './styles'

import { ServiceCard } from '@/components/molecules/ServiceCard'
import { cardContentLanguage } from '@/utils/constants/cardsContent'

import { useRouter } from 'next/router'

export function OurSolutionsSection({
  title,
  tag,
  subText,
}: LanguageContent['ourSolutions']) {
  const { locale } = useRouter()
  const { CARD_CONTENT } = cardContentLanguage(locale)

  return (
    <Container
      data-testid="our-solutions-section"
      id={SECTION_PATHS.ourSolutions}
    >
      <Image
        className="background"
        src={ourSolutionsBannerImg}
        alt="HomePage Illustration"
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        loading="lazy"
      />
      <div>
        <span>{tag}</span>
        <h2>{title}</h2>
        <p>{subText}</p>
        <nav>
          {CARD_CONTENT.map((card, index) => (
            <ServiceCard item={card} key={`${card.text} - ${index}`} />
          ))}
        </nav>
      </div>
    </Container>
  )
}
