import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: 600px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`
