import { DEVICE } from '@/utils/constants/breakpoints'
import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  display: flex;
  padding: 2.188rem 1.094rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 1280px) {
    padding: 4.375rem 7.25rem 5.875rem;
    flex-direction: row;
  }

  article {
    max-width: 576px;

    h2 {
      color: ${(props) => props.theme.colors?.base800};
      font-family: ${(props) => props.theme.fonts?.second};
      font-size: 2.625rem;
      margin: 0;
    }

    > div {
      font-family: ${(props) => props.theme.fonts?.primary};
      line-height: 1.625rem;

      a {
        color: ${(props) => props.theme.colors?.primary};
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
      }

      em {
        font-style: normal;
        color: #97b8c8;
      }
    }

    div + div {
      margin-top: 0;
      @media ${DEVICE.tablet} {
        margin-top: 4.375rem;
      }
    }
  }
`

export const MediumScreen = styled.div`
  display: block;

  @media (min-width: 1280px) {
    display: none !important;
  }
`

export const OnlyDesktop = styled.div`
  display: none;
  @media (min-width: 1280px) {
    display: flex;
  }
`
